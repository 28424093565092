import { useAccountApi } from '~/api/account'
import { useAccountStore } from '~/store/account'
import { useUserApi } from '~~/api/user'
import { useAppStore } from '~~/store/app'

export const useInitData = () => {
  const { $axios, $pinia } = useNuxtApp()
  const { setCurrentUserRank } = useAppStore($pinia)
  const { CURRENT_RANK } = useUserApi()
  const { DEPOSIT_INFO, CARD_LIST, PLAN } = useAccountApi()
  const { initDataDeposit, updatePlan, updateCardList } = useAccountStore()

  const fetchInitData = async () => {
    try {
      const { data: response } = await $axios.get(DEPOSIT_INFO)
      const { data: responseCard } = await $axios.get(CARD_LIST)
      if (response.status === 'OK') {
        let packages = response.data.packages
        packages = packages.sort((a: any, b: any) => a.id - b.id)
        initDataDeposit({ ...response.data, packages })
      }
      if (responseCard && responseCard.status === 1) {
        updateCardList(responseCard.cardlist)
      } else {
        updateCardList([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchUserRank = async () => {
    try {
      const { data: response } = await $axios.get(CURRENT_RANK, { progress: false })
      if (response && response.status === 'OK') {
        const level = response.data?.level
        setCurrentUserRank(level)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchPlan = async () => {
    try {
      const { data: response } = await $axios.get(PLAN)
      if (response && response.status === 'OK') {
        updatePlan(response.data)
      }
    } catch (error) {}
  }

  return {
    fetchPlan,
    fetchInitData,
    fetchUserRank
  }
}
